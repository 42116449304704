import React, { useState } from 'react';
import { useSecurity } from '../../../helpers/SecurityHooks';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { appendScriptsCallback, formatDate, formatDateTime } from '../../../helpers/Utils';
import { useParams } from 'react-router-dom';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { getEntityDetails, getPreferredDetails  } from '../../../../services/ApiServices';


interface ITradingAnnualBudgetLineChange {
  LoadingInfo: {
    CreationDate?: string,
    CreatedBy?: string,
    CreatedByDisplayValue?: string,
    UpdateDate?: string,
    UpdatedBy?: string,
    UpdatedByDisplayValue?: string,
    SourceStatus?: number,
    Source?: number
  },
  AnnualBudgetLineId?: string,
  AnnualBudgetLineDisplayValue?: string,
  ClientProfileDisplayValue?: string,
  DistributionTypeDisplayValue?: string,
  MediaGroupDisplayValue?: string,
  MediaNetworkDisplayValue?: string,
  DayPartDisplayValue?: string,
  MarketDisplayValue?: string,
  ProgramTypeDisplayValue?: string,
  GrossNet?: number,
  Guaranteed?: number,
  MeasurementCurrency?: number,
  GuaranteedAudienceDisplayValue?: string,
  ClientAudienceDisplayValue?: string,
  ConversionAudienceDisplayValue?: string,
  TargetingType?: number,
  Notes?: string,
  DealDisplayValue?: string,
  Hispanic?: number,
  Hold?: number,
  Status?: number,
  Code?: string,
  Display?: string,
  EntityStatus?: number,
  SourceReference?: string,
  Id: string,
  InactiveFrom?: Date
}

	
// eslint-disable-next-line @typescript-eslint/no-unused-vars
enum AnnualBudgetChangeStatusEnum { 'Pending' = 0, 'Approved' = 1, 'Rejected' = 2, 'Editing' = 3 }
enum ApplianceModeEnum { 'Yes' = 0, 'No' = 1 }
enum NetGrossFlagEnum {
  'Net' = 0,
  'Gross' = 1,
}
enum MeasurementCurrencyEnum {
  'Nielsen One' = 0,
  'Nielsen DAR' = 1,
  'Comscore' = 2,
  'iSpot' = 3,
  'VideoAmp' = 4,
  'Cogent' = 5,
  'Nielsen Standard' = 6,
  'Crossix' = 7
}
enum TargetingTypeEnum {
  'Retarget' = 0,
  'Demo' = 1,
  'Behavioral' = 2,
  'Contextual' = 3,
  'Geo' = 4,
  'Multicultural' = 5
}


export function TradingAnnualBudgetLineChangeDetailCustomPage(): React.ReactElement {
  const { id } = useParams();
  const [state, setState] = useState<ITradingAnnualBudgetLineChange>({ Id: "" , LoadingInfo: {}  });
  const currentSecurity = useSecurity('Trading', 'AnnualBudgetLineChange');
  React.useEffect(() => {
    if (state.Id == "" && currentSecurity.checkAccess()) {
      const fields = ["AnnualBudgetLine.ClientProfile.Alias as ClientProfileDisplayValue",
        "AnnualBudgetLine.DistributionType.EnglishName as DistributionTypeDisplayValue",
        "AnnualBudgetLine.MediaNetworkProfile.MediaGroup.EnglishName as MediaGroupDisplayValue",
        "AnnualBudgetLine.MediaNetworkProfile.Alias as MediaNetworkDisplayValue",
        "AnnualBudgetLine.MediaNetworkProfile.Hispanic as Hispanic",
        "AnnualBudgetLine.Market.EnglishName as MarketDisplayValue",
        "AnnualBudgetLine.ProgramType.EnglishName as ProgramTypeDisplayValue",
        "AnnualBudgetLine.GrossNet as GrossNet",
        "AnnualBudgetLine.Guaranteed as Guaranteed",
        "AnnualBudgetLine.AnnualBudgetId as AnnualBudgetId",
        "AnnualBudgetLine.AnnualBudget.ForecastYear as ForecastYear",
        "AnnualBudgetLine.AnnualBudget.AgencyProfileId as AgencyProfileId",
        "AnnualBudgetLine.AnnualBudget.AgencyProfile.AgencyId as AgencyId",
        "AnnualBudgetLine.ClientProfileId as ClientProfileId",
        "AnnualBudgetLine.Mode as Mode",
        "AnnualBudgetLine.Type as Type",
        "AnnualBudgetLine.MeasurementCurrency as MeasurementCurrency",
        "AnnualBudgetLine.GuaranteedAudience.EnglishName as GuaranteedAudienceDisplayValue",
        "AnnualBudgetLine.ClientAudience.EnglishName as ClientAudienceDisplayValue",
        "AnnualBudgetLine.ConversionAudience.EnglishName as ConversionAudienceDisplayValue",
        "AnnualBudgetLine.TargetingType as TargetingType",
        "AnnualBudgetLine.Notes as Notes",
        "AnnualBudgetLine.DealCommitment.DealName as DealDisplayValue",
        "AnnualBudgetLine.DayPart.Alias as DayPartDisplayValue"];
      getEntityDetails("Trading", "AnnualBudgetLineChange", id, fields).then(result => {
        setState(result.Value[0] as ITradingAnnualBudgetLineChange);
        appendScriptsCallback(["Areas/Trading/Scripts/Resources/en/res.AnnualBudgetLineChange.en.js",
        "Areas/Trading/Scripts/Resources/en/Types.en.js",
        "Areas/Trading/Scripts/Enums.js",
        "Areas/Trading/Scripts/AnnualBudgetLineChange/View.js"
        ,"Areas/Trading/Scripts/Resources/en/res.AnnualBudgetDataChange.en.js"
        ,"Areas/Trading/Scripts/AnnualBudgetDataChange/View.js"
        ], "initDetailTradingAnnualBudgetLineChange", { viewmodel: result.Value[0], canEdit: false, canCreate: true, canRemove: true, id: id});
      });
    }
  }, []);
  window['reloadTradingAnnualBudgetLineChangeState'] = (data) => {
    setState(data.Value[0] as ITradingAnnualBudgetLineChange);
  };
  return (
    <div className="container-fluid">
      <section id="patron" style={{ display: 'none' }}>
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
            <div id="AnnualBudgetLineChange" className="controls-container default block">
              <div className="content-label">
                <div className="content-title">
                  <div className="k-content">
                    <h3>Change approval requested by <span>{state.LoadingInfo.CreatedByDisplayValue}</span> for:</h3>
                    </div>
                </div>
                <div className='content-line'>
                  {state.ClientProfileDisplayValue && <span className='element'><label>Client Profile: <label className="strong">{state.ClientProfileDisplayValue}</label></label></span>}
                  {state.DistributionTypeDisplayValue && <span className='element'><label>Media Type: <label className="strong">{state.DistributionTypeDisplayValue}</label></label></span>}
                  {state.MediaGroupDisplayValue && <span className='element'><label>Media Group: <label className="strong">{state.MediaGroupDisplayValue}</label></label></span>}
                  {state.MediaNetworkDisplayValue && <span className='element'><label>Media Network: <label className="strong">{state.MediaNetworkDisplayValue}</label></label></span>}
                  <span className='element'><label>Hispanic: <label className="strong">{state.Hispanic != undefined ? ApplianceModeEnum[state.Hispanic] : ''}</label></label></span>
                  {state.DayPartDisplayValue && <span className='element'><label>Daypart: <label className="strong">{state.DayPartDisplayValue}</label></label></span>}
                </div>
                <div className="content-line">
                  {state.MarketDisplayValue && <span className='element'><label>Market: <label className="strong">{state.MarketDisplayValue}</label></label></span>}
                  {state.DealDisplayValue && <span className='element'><label> Deal: <label className="strong">{state.DealDisplayValue}</label></label></span>}
                  {state.ProgramTypeDisplayValue && <span className='element'><label>Genre: <label className="strong">{state.ProgramTypeDisplayValue}</label></label></span>}
                  <span className='element'><label>GrossNet: <label className="strong">{state.GrossNet != undefined ? NetGrossFlagEnum[state.GrossNet] : ''}</label></label></span>
                  <span className='element'><label>Guaranteed: <label className="strong">{state.Guaranteed != undefined ? ApplianceModeEnum[state.Guaranteed] : ''}</label></label></span>
                  <span className='element'><label>Measurement Currency: <label className="strong">{state.MeasurementCurrency != undefined ? MeasurementCurrencyEnum[state.MeasurementCurrency] : ''}</label></label></span>
                </div>
                <div className="content-line">
                  {state.GuaranteedAudienceDisplayValue && <span className='element'><label> GuaranteedAudience: <label className="strong">{state.GuaranteedAudienceDisplayValue}</label></label></span>}
                  {state.ClientAudienceDisplayValue && <span className='element'><label> ClientAudience: <label className="strong">{state.ClientAudienceDisplayValue}</label></label></span>}
                  {state.TargetingType && <span className='element'><label>TargetingType: <label className="strong">{state.TargetingType != undefined ? TargetingTypeEnum[state.TargetingType] : ''}</label></label></span>}
                  {state.ConversionAudienceDisplayValue && <span className='element'><label> ConversionAudience: <label className="strong">{state.ConversionAudienceDisplayValue}</label></label></span>}
                  <span className='element'><label>Status: <label className="strong">{state.Status != undefined ? AnnualBudgetChangeStatusEnum[state.Status] : ''}</label></label></span>
                  {state.Notes && <span className='element'><label> Notes: <label className="strong">{state.Notes}</label></label></span>}
                </div>
              </div>
              <form id="form" action="" style={{ display: 'none'}} className="content-view row">
		<div className="col-md-2" ><label>Code</label><input id="AnnualBudgetLineChangeCode" max="20" name="Code" type="text" defaultValue={state.Code} className="shortcontrol disabled k-input k-input-solid k-textbox" readOnly disabled  placeholder="AUTO-CODE" /></div>
			<div className="col-md-2">
				<label id="AnnualBudgetLineChangeLabelBlockedCode">Blocked</label>
				<input id="AnnualBudgetLineChangeCheckBlockedCode" defaultChecked name="AnnualBudgetLineChangeCheckBlockedCode" type="checkbox" />
			</div>
			<div className="clear"></div>
		<div className="col-md-4 combobox  " ><label htmlFor="AnnualBudgetLineId">Annual  Budget  Line</label><select className="" id="AnnualBudgetLineChangeAnnualBudgetLineId" name="AnnualBudgetLineId" defaultValue={state.AnnualBudgetLineId}   required data-required-msg="Annual  Budget  Line is required"  >{state.AnnualBudgetLineId && <option value={state.AnnualBudgetLineId}>{state.AnnualBudgetLineDisplayValue}</option>}</select></div>
		<div className="col-md-2 dropdownlist  " >
      <label htmlFor="Status" className="required">Status</label>
      <select className="" id="AnnualBudgetLineChangeStatus" name="Status" value={state.Status} required data-required-msg="Status is required" >
        <option value="0">Pending</option>
        <option value="1">Approved</option>
        <option value="2">Rejected</option>
        <option value="3">Editing</option>
      </select>
    </div>
		<div className="col-md-4" ><label htmlFor="SourceReference">Source Reference</label><input id="AnnualBudgetLineChangeSourceReference" max="60" name="SourceReference" type="text" defaultValue={state.SourceReference} className="shortcontrol k-input k-input-solid k-textbox"  placeholder="SOURCE-REFERENCE" /></div>
                <div className="clear"></div>
                <div className="content-header">Tracking</div>
                <div className="col-md-4" ><label htmlFor="LoadingInfo_SourceStatus">Loading Status</label><select className="" id="AnnualBudgetLineChangeSourceStatus" name="LoadingInfo.SourceStatus" value={state.LoadingInfo.SourceStatus} ><option value="0">Loaded</option><option value="1">Processed</option><option value="2">Pending</option><option value="3">Reloaded</option><option value="4">Error</option></select></div><div className="col-md-4" ><label htmlFor="LoadingInfo_Source">Source</label><select className="" id="AnnualBudgetLineChangeSource" name="LoadingInfo.Source" value={state.LoadingInfo.Source} ><option value="0">Owned</option><option value="1">ExternalPlanning</option><option value="2">ExternalBuying</option><option value="3">ExternalFinance</option><option value="4">ExternalHub</option></select></div><div className="clear"></div>
                <div className="trackinfo">
                    <div className="line">Created on <span className="when">{formatDateTime(state.LoadingInfo.CreationDate)}</span> by <span className="who">{state.LoadingInfo.CreatedByDisplayValue}</span> </div>
                    <div className="line">Last Update on <span className="when">{formatDateTime(state.LoadingInfo.UpdateDate)}</span> by <span className="who">{state.LoadingInfo.UpdatedByDisplayValue}</span> </div>
                </div>
              </form>
              <section className="button-toolbar" style={{ display: 'none'}} id="ToolbarAnnualBudgetLineChange">
                <div className="left">
                  <button id="cancelAnnualBudgetLineChangeButton" type="button" className="k-button button" title="Cancel">Cancel</button>
                </div>
		            <div className="right">
                  <button id="saveAnnualBudgetLineChangeButton" type="button" className="k-button button" title="Save">Save</button>
                </div>
              </section>
            </div>
          </section>
            <div id="associationAnnualBudgetLineChangeAnnualBudgetDataChange">
              <div className="k-block grid-details">
                <h2>Quarterly Budget Data Proposed Change</h2>
              <div id="AnnualBudgetDataChangeGrid" className="grid-control"></div>
              </div>
            </div>
        </section>
      </section>
    </div>
  );
}

export function TradingAnnualBudgetLineChangeApproveCustomPage(): React.ReactElement {
  const { id } = useParams();
  const [state, setState] = useState<ITradingAnnualBudgetLineChange>({ Id: "", LoadingInfo: {} });
  const currentSecurity = useSecurity('Trading', 'AnnualBudgetLineChange');
  React.useEffect(() => {
    if (state.Id == "" && currentSecurity.checkAccess()) {
      const fields = ["AnnualBudgetLine.ClientProfile.Alias as ClientProfileDisplayValue",
        "AnnualBudgetLine.MediaNetworkProfile.MediaGroup.EnglishName as MediaGroupDisplayValue",
        "AnnualBudgetLine.MediaNetworkProfile.MediaGroupId as MediaGroupId",
        "AnnualBudgetLine.AnnualBudgetId as AnnualBudgetId",
        "AnnualBudgetLineId as AnnualBudgetLineId",
        "AnnualBudgetLine.AnnualBudget.ForecastYear as ForecastYear",
        "AnnualBudgetLine.AnnualBudget.AgencyProfileId as AgencyProfileId",
        "AnnualBudgetLine.AnnualBudget.AgencyProfile.AgencyId as AgencyId",
        "AnnualBudgetLine.ClientProfileId as ClientProfileId",
        "AnnualBudgetLine.Mode as Mode",
        "AnnualBudgetLine.Hold as Hold",
        "AnnualBudgetLine.Type as Type"];
      getEntityDetails("Trading", "AnnualBudgetLineChange", id, fields).then(result => {
        setState(result.Value[0] as ITradingAnnualBudgetLineChange);
        appendScriptsCallback(["Areas/Trading/Scripts/Resources/en/res.AnnualBudgetLineChange.en.js",
          "Areas/Trading/Scripts/Resources/en/Types.en.js",
          "Areas/Trading/Scripts/Resources/en/res.Trading.en.custom.js",
          "Areas/Trading/Scripts/Enums.js",
          "Areas/Trading/Scripts/AnnualBudgetLineChange/View.js"
          , "Areas/Trading/Scripts/Resources/en/res.AnnualBudgetDataChange.en.js"
          , "Areas/Trading/Scripts/AnnualBudgetDataChange/View.custom.js"
        ], "initDetailTradingAnnualBudgetLineChangeApprove", { viewmodel: result.Value[0], canEdit: false, canCreate: true, canRemove: true, id: id });
      });
    }
  }, []);
  window['reloadTradingAnnualBudgetLineChangeState'] = (data) => {
    setState(data.Value[0] as ITradingAnnualBudgetLineChange);
  };
  return (
    <div className="container-fluid">
      <section id="patron" style={{ display: 'none' }}>
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
            <div id="AnnualBudgetLineChange" className="controls-container default block">
              <div className="content-label">
                <div className="content-title">
                  <div className="k-content">
                    <h3>Change approval requested by <span>{state.LoadingInfo.CreatedByDisplayValue}</span> for:</h3>
                  </div>
                </div>
                <div className='content-line'>
                  {state.ClientProfileDisplayValue && <span className='element'><label>Client Profile: <label className="strong">{state.ClientProfileDisplayValue}</label></label></span>}
                  {state.MediaGroupDisplayValue && <span className='element'><label>Media Group: <label className="strong">{state.MediaGroupDisplayValue}</label></label></span>}
                  <span className='element'><label>Hold: <label className="strong">{state.Hold != undefined ? ApplianceModeEnum[state.Hold] : ''}</label></label></span>
                </div>
              </div>
              <form id="form" action="" style={{ display: 'none' }} className="content-view row">
                <div className="col-md-2" ><label>Code</label><input id="AnnualBudgetLineChangeCode" max="20" name="Code" type="text" defaultValue={state.Code} className="shortcontrol disabled k-input k-input-solid k-textbox" readOnly disabled placeholder="AUTO-CODE" /></div>
                <div className="col-md-2">
                  <label id="AnnualBudgetLineChangeLabelBlockedCode">Blocked</label>
                  <input id="AnnualBudgetLineChangeCheckBlockedCode" defaultChecked name="AnnualBudgetLineChangeCheckBlockedCode" type="checkbox" />
                </div>
              </form>
            </div>
          </section>
          <div id="associationAnnualBudgetLineChangeAnnualBudgetDataChange">
            <div className="k-block grid-details">
              <h2>Quarterly Budget Data Proposed Change</h2>
              <span style={{ color: 'red', fontSize: '1rem' }}>To Approve or Reject all changes, please use either the Approve or Reject button at the top of the page. To mark individual lines as approved or rejected use the <span style={{ color: "green"}} className="fa fa-check"></span> or <span className="fa fa-x"></span> at the start of each line item</span>
              <div id="AnnualBudgetContainer" className="k-content">
                <div id="AnnualBudgetLineContent">
                  <div id="AnnualBudgetLineContainer" className="spreadsheet-container">
                    <div id="AnnualBudgetLineSpreadsheet" style={{ width: '100%' }}></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </div>
  );
}


